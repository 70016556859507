body.landing {
    main {
        max-width: 1920px;
        margin: 0 auto;
    }

    .img-containers-wrapper {
        --car-img-height: 240px;

        @media (min-width: 667px) {
            --car-img-height: 350px;
        }

        @media (min-width: 992px) {
            --car-img-height: 500px;
        }

        @media (min-width: 1200px) {
            --car-img-height: 600px;
        }

        @media (min-width: 1600px) {
            --car-img-height: 700px;
        }
    }

    .btn-maserati-w {
        margin-left: 10%;

        @media (min-width: 992px) {
            margin-left: 15%;
        }
    }

    .evt-title {
        font-size: 25px;
    }
    @media (min-width: 992px) {
        .evt-title {
            font-size: 45px;
        }
    }
}
