body.policy {
    .jform-group {
        width: fit-content;
    }

    .policy-select {
        background-color: $form-maserati;
        width: 100%;
        max-width: 540px;
        padding: 30px 20px;
        justify-content: space-evenly;
        //align-items: center;
        a {
            font-size: 22px;
        }
    }

    .jform-title {
        color: $giallo;
    }
}
