@font-face {
    font-family: 'Everett';
    src: url('/assets/fonts/Everett-Medium.eot');
    src:
        url('/assets/fonts/Everett-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Everett-Medium.woff2') format('woff2'),
        url('/assets/fonts/Everett-Medium.woff') format('woff'),
        url('/assets/fonts/Everett-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Everett Jana';
    src: url('/assets/fonts/EverettJana-Regular.eot');
    src:
        url('/assets/fonts/EverettJana-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/EverettJana-Regular.woff2') format('woff2'),
        url('/assets/fonts/EverettJana-Regular.woff') format('woff'),
        url('/assets/fonts/EverettJana-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Everett';
    src: url('/assets/fonts/Everett-Light.eot');
    src:
        url('/assets/fonts/Everett-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Everett-Light.woff2') format('woff2'),
        url('/assets/fonts/Everett-Light.woff') format('woff'),
        url('/assets/fonts/Everett-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Everett';
    src: url('/assets/fonts/Everett-Regular.eot');
    src:
        url('/assets/fonts/Everett-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Everett-Regular.woff2') format('woff2'),
        url('/assets/fonts/Everett-Regular.woff') format('woff'),
        url('/assets/fonts/Everett-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
