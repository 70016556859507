@mixin scrollbars($size: 10px, $foreground-color: #999, $background-color: #333, $border-radius: 8px) {
    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: $border-radius;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}
