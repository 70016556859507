.btn-form-submit {
    //
    //    color: $text-color;
    //    border-radius: 0;
    //    font-size: 26px;
    //    margin: 0 auto;
    //    text-align: center;
    //    font-weight: normal;
    //    border: 1px solid $white;
    margin-top: 5rem !important;
    //
    //    &:hover, &:active {
    //        font-weight: bold;
    //        color: $text-color;
    //        // background-color: $text-highlight;
    //        // border: 1px solid $text-highlight;
    //
    //    }
    //    &:focus, &:active {
    //        transform: scale(0.95);
    //        // color: $text-reverse;
    //        // background-color: $text-highlight;
    //        // border: 1px solid $text-highlight;
    //
    //    }
}
.btn-fs-med {
    font-size: 20px;
}
.btn-maserati-w {
    border: 1px solid white;
    padding: 18px 30px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    color: $text-color;
}
a.btn-maserati-w:after {
    content: '❯';
    font-weight: bold;
    margin-left: 30px;
}
.btn-maserati-w:hover {
    color: $giallo;
}
.btn-maserati-b {
    border: 1px solid black;
    padding: 18px 30px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    color: $nero;
}
a.btn-maserati-b:after {
    content: '❯';
    font-weight: bold;
    margin-left: 30px;
}
.btn-maserati-b:hover {
    color: $giallo;
}
.btn-maserati-y {
    border: 1px solid $giallo;
    background: $giallo;
    padding: 18px 30px;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    min-width: 100px;
    color: $text-reverse;
}
.btn-maserati-y:after {
    content: '❯';
    font-weight: bold;
    margin-left: 120px;
}

.btn-maserati-y:hover {
    color: $bianco;
}
.btn-form-submit {
    border: 1px solid white;
    padding: 18px 30px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    min-width: 100px;
    color: $nero;
    background: $bianco;
}
.btn-form-submit:after {
    content: '❯';
    font-weight: bold;
    margin-left: 90px;
}
.btn-form-submit:hover {
    color: $giallo;
}
.btn-go-back {
    border: 1px solid white;
    padding: 18px 30px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    min-width: 100px;
    color: $nero;
    background: $bianco;
}
.btn-go-back:before {
    content: '❮';
    font-weight: bold;
    margin-right: 90px;
}
.back:after {
    content: '❮';
}
.btn-go-back:hover {
    color: $giallo;
}
// buttons for languages
ul.header__languages.d-flex {
    justify-content: end;
    margin-bottom: 15px;
    position: absolute;
    right: 25px;

    @media screen and (min-width: 980px) {
        justify-content: center;
        margin-bottom: 0;
    }

    li {
        margin: 0 0 0 10px;
        @media screen and (min-width: 980px) {
            margin: 0 10px;
        }

        div {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 40px;
            height: 40px;
            background-size: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0.5;
            &.lang-active {
                opacity: 1 !important;
            }
            &:hover {
                opacity: 1 !important;
            }
        }
    }
}

@media (min-width: 768px) {
    .btn-form-submit {
        -webkit-box-flex: 0;
        flex: 0 0 41.66666%;
        max-width: 30%;
    }
}
