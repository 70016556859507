body.register-confirm {
    main {
        max-width: 1920px;
    }

    .img-containers-wrapper {
        --car-img-height: 240px;
        --car-img-title-fs: 20px;

        @media (min-width: 667px) {
            --car-img-height: 350px;
            --car-img-title-fs: 22px;
        }

        @media (min-width: 992px) {
            --car-img-height: 500px;
            --car-img-title-fs: 30px;
        }

        @media (min-width: 1200px) {
            --car-img-height: 600px;
            --car-img-title-fs: 36px;
        }

        @media (min-width: 1600px) {
            --car-img-height: 700px;
            --car-img-title-fs: 40px;
        }
    }

    .btn-maserati-w {
        margin-left: 10%;

        @media (min-width: 992px) {
            margin-left: 15%;
        }
    }

    .evt-title {
        font-size: var(--car-img-title-fs, 20px);
    }
    .evt-subtitle {
        font-size: calc(var(--car-img-title-fs) * 0.7);
    }
}
