.helpcrunch-iframe-wrapper {
    iframe {
        //right: 10px!important;
        left: auto !important;
        top: 60px !important;
        // box-shadow: 0 0 60px 0 rgb(135 14 17 / 90%);
    }
}
#av-live {
    width: 100%;
    background-color: $av-live-background;
    .vjs-big-play-button {
        display: none;
    }
    .symbol-close-chat {
        border: 1px solid white;
        padding: 4px 8px;
        font-size: 12px;
    }
    .symbol-open-chat {
        border: 1px solid white;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 20px;
        display: block;
        width: 30%;
        min-width: 100px;
    }
    #header-close-chat {
        margin-top: 20px;
        height: 26px !important;
    }
}
.live-alfa {
    padding: 1rem 4rem !important;
}

#av-content {
    position: relative;
    background-color: $av-content-background;
    padding: 0;

    .av-live-frame-border {
        border: 1px solid #fff;
        // background-color: $av-live-frame-border;
        // padding: 15px;
    }
}
#teleport-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: black;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 0 25px;
        text-align: center;
    }
    .title {
        font-size: 18px;
    }
    .subtitle {
        font-size: 16px;
    }
}
#av-aside {
    position: relative;
    background-color: $av-aside-background;
    padding: 0 0 25px 0;
    display: flex;
    justify-content: flex-end;

    .inner {
        height: 92%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .help {
        border: 1px solid white;
        //margin: 15px;
        padding: 10px;
        display: inline-block;
        width: 30%;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        min-width: 100px;
    }
}
.chat-open {
    #av-aside {
        #btn-toggle-room-status {
            //border: 2px solid white;
            //color: #ffff;
            //padding: 10px 20px;
            display: none;
        }
    }
}

#btn-toggle-room-status {
    font-size: 16px;
    text-shadow: none;
    box-shadow: none;
    border: 2px solid white;
    color: #ffff;
    padding: 10px 20px;
    margin-bottom: 30px;
}

#room-box {
    margin: 2rem auto;
    padding: 5px;
    //max-width: 500px;
    background-color: transparent;
}
#room-box-sponsor {
    padding: 0 10px;
    max-width: 500px;
    margin: 0 auto;
}
#room-box-title {
    margin-top: auto;
    .title {
        background-color: $light-background;
        color: #fff;
        border: 2px solid white;
        border-radius: 5px;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
    }
}
#room-teleport {
    margin-top: auto;
    .title {
        background-color: $light-background;
        color: #fff;
        border: 2px solid white;
        border-radius: 5px;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 11px;
        text-align: center;
        display: block;
        margin: 10px 0px;
    }
}
#video-box,
#iframe-box {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;

    #live {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

#av-live[data-av-theme='live-aside'] {
    #room-box {
    }
}

@media (min-width: 768px) {
    .helpcrunch-iframe-wrapper {
        iframe {
            //right: 10px!important;
            left: auto !important;
            top: auto !important;
            bottom: 15px !important;
        }
    }
    .chat-open {
        #av-aside {
            display: inline-block;
            #btn-toggle-room-status {
            }
        }
    }
    .chat-closed {
        #av-aside {
            display: flex;
            justify-content: flex-end;

            #btn-toggle-room-status {
                right: -80px;
            }
        }
    }
    #btn-toggle-room-status {
        //width: 80px;
    }
    #av-aside {
        position: relative;
        background-color: $av-aside-background;
        padding: 0 0 25px 0;
    }
    #teleport-overlay {
        .title {
            font-size: 22px;
        }
        .subtitle {
            font-size: 20px;
        }
    }
}

@media (min-width: 992px) {
    .chat-open {
        #av-aside {
            padding-left: 30px;
            margin-left: 30px;
            border-left: 1px solid white;
        }
    }
    .chat-closed {
        #av-aside {
            border-left: none;
            margin-left: 0px;
            padding-left: 25px;
        }
    }
    #av-live {
        width: 75%;
        padding-bottom: 50px;
        .symbol-open-chat {
            margin-top: 0px;
            width: unset;
        }
        #header-close-chat {
            margin-top: 0px;
        }
    }

    #av-live[data-av-theme='live-full-chat'] {
        // spazio verticale per area video
        // header 83px + footer 130 px + padding soprasotto (15*2) 30px = 243px

        width: min(100vw - 30px, (100vh - 83px - 130px - 30px) * 16/9);
        margin-left: auto;
        margin-right: auto;

        .video-js .vjs-fullscreen-control {
            margin-right: min(400px, 50%);
        }

        #room-box {
            margin: 0;
            width: min(400px, 50%);
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
        }
    }

    #av-live[data-av-theme='live-aside'] {
        justify-content: center;
        #av-aside {
            //max-width: 500px;
            //padding-left: 25px;
        }
        #room-box {
            margin: 0;
        }
    }
}
@media (min-width: 768px) {
    // #room-box-title {
    //     top: 20px;

    //     .title {
    //         font-size: 30px;
    //     }
    // }
}
@media (min-width: 992px) {
    // #room-box-title {
    //     top: -20px;

    //     .title {
    //         font-size: 30px;
    //     }
    // }

    #av-aside {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
