body.thankyou {
    .title {
        font-size: 20px;
        font-weight: 100;
        padding-top: 50px;
    }

    .subtitle {
        font-size: 18px;
        font-weight: 100;
        padding-top: 1px;
    }

    @media (min-width: 768px) {
        .title {
            font-size: 25px;
        }

        .subtitle {
            font-size: 18px;
        }
    }

    @media (min-width: 992px) {
        .title {
            font-size: 32px;
        }

        .subtitle {
            font-size: 25px;
        }
    }

    @media (min-width: 1200px) {
        .title {
            font-size: 50px;
            padding-top: 0px;
        }

        .subtitle {
            font-size: 35px;
        }
    }

    @media (min-width: 1400px) {
    }

    #av-poster {
    }

    .av-poster-wide {
        .poster-wide {
            border: 1px solid $text-color;
        }
    }
    .poster-wide {
        border: 1px solid $text-color;
    }
}
