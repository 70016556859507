body.countdown {
    .evt-title {
        font-size: var(--car-img-title-fs, 30px);
    }

    .evt-subtitle {
        font-size: var(--evt-subtitle-fs, 18px);
        text-wrap: balance;
    }

    .up-to-date-box {
        width: 100%;
        background: $bianco;
        padding: 50px 0;
        > div {
            width: 100%;
        }

        .padding-box {
            padding: 75px;
            display: flex;
            flex-direction: column;
            color: $text-reverse;
            align-items: center;
        }

        @media (min-width: 992px) {
            display: flex;
            align-items: center;

            > div {
                width: 50%;
            }

            .padding-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .img-containers-wrapper {
        //min-height: calc(var(--car-img-height) * 1.25);
    }

    main {
        //min-height: calc(var(--car-img-height) * 1.25);
        --car-img-height: 240px;
        --car-img-title-fs: 24px;

        .countdown-wrapper {
            padding-bottom: 25px;
        }

        @media (min-width: 430px) {
            --car-img-height: 350px;
            --car-img-title-fs: 30px;
        }
        @media (min-width: 667px) {
            --car-img-height: 350px;
        }

        @media (min-width: 768px) {
            --car-img-height: 350px;
            --car-img-title-fs: 40px;
            --evt-subtitle-fs: 20px;
        }

        @media (min-width: 992px) {
            --car-img-height: 500px;
            --car-img-title-fs: 50px;

            .countdown-wrapper {
                --car-img-title-fs: 30px;
            }
        }

        @media (min-width: 1200px) {
            --car-img-height: 600px;
            --evt-subtitle-fs: 24px;

            .countdown-wrapper {
                position: absolute;
                top: 0;
                width: 100%;
                padding-bottom: 0;
            }
        }

        @media (min-width: 1600px) {
            --car-img-height: 700px;
        }
    }

    .countdown-box {
        width: fit-content;
        margin: 0 auto;
    }
}

#time2go {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    padding-top: 0;
    //	padding: 15px;

    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        text-transform: capitalize;
        //padding: 0 12px;
        //max-width: 160px;

        .short {
            display: none;
        }

        .long {
            font-size: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '  ';
            }
        }
        // &:first-child {

        //     .t2-digit:after {
        //         content: '';
        //     }
        // }
    }
    .t2-part {
        line-height: 1;
        //background-color: $text-color;
        color: $giallo;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .t2-digit {
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 10px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            font-size: 40px;
            right: -5px;
        }
    }
}
.countdown-position {
    justify-content: flex-start !important;
    padding-top: 4rem !important;
}

@media (min-width: 375px) {
    #time2go {
        .t2-counter {
            .long {
                font-size: 12px;
            }
        }

        .t2-digit {
            font-size: 40px;
            width: 80px;
        }
    }
}

@media (min-width: 768px) {
    #time2go {
        .t2-counter {
            .long {
                font-size: 16px;
            }
        }

        .t2-digit {
            font-size: 60px;
            width: 120px;
        }
    }
}

@media (min-width: 992px) {
    #time2go {
        .t2-counter {
            width: 100%;

            .long {
                font-size: 24px;
            }
        }

        .t2-digit {
            font-size: 75px;
            width: 150px;

            &:after {
                right: -8px;
            }
        }
    }
}

@media (min-width: 1200px) {
    #time2go {
        .t2-digit {
            margin-bottom: 0;
        }
    }
}
